exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mtb-fork-demper-dropper-onderhoud-tsx": () => import("./../../../src/pages/mtb-fork-demper-dropper-onderhoud.tsx" /* webpackChunkName: "component---src-pages-mtb-fork-demper-dropper-onderhoud-tsx" */),
  "component---src-pages-mtb-ketting-onderhoud-tsx": () => import("./../../../src/pages/mtb-ketting-onderhoud.tsx" /* webpackChunkName: "component---src-pages-mtb-ketting-onderhoud-tsx" */),
  "component---src-pages-mtb-onderhoud-tsx": () => import("./../../../src/pages/mtb-onderhoud.tsx" /* webpackChunkName: "component---src-pages-mtb-onderhoud-tsx" */),
  "component---src-pages-mtb-onderhoudsbeurten-tsx": () => import("./../../../src/pages/mtb-onderhoudsbeurten.tsx" /* webpackChunkName: "component---src-pages-mtb-onderhoudsbeurten-tsx" */),
  "component---src-pages-mtb-remmen-onderhoud-tsx": () => import("./../../../src/pages/mtb-remmen-onderhoud.tsx" /* webpackChunkName: "component---src-pages-mtb-remmen-onderhoud-tsx" */),
  "component---src-pages-mtb-wassen-tsx": () => import("./../../../src/pages/mtb-wassen.tsx" /* webpackChunkName: "component---src-pages-mtb-wassen-tsx" */),
  "component---src-pages-overbrengingsverhoudingen-tsx": () => import("./../../../src/pages/overbrengingsverhoudingen.tsx" /* webpackChunkName: "component---src-pages-overbrengingsverhoudingen-tsx" */)
}

